var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoadingPerformanceConcept || _vm.isUpdatingPerformanceConcept,"rounded":""},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner'),_vm._v(" "),_c('br'),_vm._v(" Cargando información de configuración ")],1)]},proxy:true}])},[_c('b-card',[_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"size":"sm","variant":"warning"},on:{"click":_vm.goToHome}},[_c('b-icon-arrow-left'),_vm._v(" Regresar")],1)],1),(_vm.currentConcept)?_c('div',[_c('validation-observer',{ref:"PerformanceConceptEdition",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveConfiguration($event)}}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Año"}},[_c('b-form-select',{attrs:{"disabled":_vm.currentConcept.disableFields},model:{value:(_vm.currentConcept.year),callback:function ($$v) {_vm.$set(_vm.currentConcept, "year", $$v)},expression:"currentConcept.year"}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.listyears),function(year){return _c('option',{key:year,domProps:{"value":year}},[_vm._v(_vm._s(year))])})],2)],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Hotel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Hotel"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null,"clearable":false,"disabled":_vm.currentConcept.disableFields},model:{value:(_vm.currentConcept.selectedHotel),callback:function ($$v) {_vm.$set(_vm.currentConcept, "selectedHotel", $$v)},expression:"currentConcept.selectedHotel"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.hotels),function(h){return _c('option',{key:h.id,domProps:{"value":h.id}},[_vm._v(_vm._s(h.name))])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Conceptos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Conceptos"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null,"clearable":false,"disabled":_vm.currentConcept.disableFields},model:{value:(_vm.currentConcept.selectConcepts),callback:function ($$v) {_vm.$set(_vm.currentConcept, "selectConcepts", $$v)},expression:"currentConcept.selectConcepts"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.catalogPerformanceConcepts),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("Valor")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentConcept.value),expression:"currentConcept.value"}],staticClass:"form-control",attrs:{"type":"text","disabled":_vm.currentConcept.disableFields},domProps:{"value":(_vm.currentConcept.value)},on:{"keypress":_vm.onlyNumberWithDecimal,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentConcept, "value", $event.target.value)}}})])],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"","name":"status"}},[_c('b-form-group',[_c('label',[_vm._v("Status")]),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.currentConcept.status),callback:function ($$v) {_vm.$set(_vm.currentConcept, "status", $$v)},expression:"currentConcept.status"}})],1)],1)],1)],1),_c('hr'),_c('b-button',{staticClass:"float-right ",attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.isUpdatingPerformanceConcept}},[_vm._v("Guardar ")])],1)]}}],null,false,2996901771)})],1):(!_vm.isLoadingPerformanceConcept && !_vm.isUpdatingPerformanceConcept)?_c('div',[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" No se han encontrado registros válidos ")])])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }