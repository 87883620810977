<template>
    <b-overlay :show="isLoadingPerformanceConcept || isUpdatingPerformanceConcept" rounded class="">
        <template #overlay>
            <div class="text-center">
                <b-spinner/> <br> Cargando información de configuración
            </div>
        </template>
        <b-card>
            <div class="text-right">
                <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
            </div>
            <div v-if="currentConcept">


                <validation-observer v-slot="{ invalid }" ref="PerformanceConceptEdition">
                    <b-form @submit.prevent="saveConfiguration">
                        <b-row  class="justify-content-center">
                            <b-col>
                                <b-form-group label="Año">
                                    <b-form-select
                                        v-model="currentConcept.year"
                                        :disabled="currentConcept.disableFields"
                                    >
                                    <option :value="null">Seleccione una opción</option>
                                    <option v-for="year in listyears" :key="year" :value="year">{{ year }}</option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>

                            <b-col>
                                <validation-provider name="Hotel" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label="Hotel">
                                        <b-form-select
                                            :state="errors[0] ? false : valid ? true : null"
                                            v-model="currentConcept.selectedHotel"
                                            :clearable="false"
                                            :disabled="currentConcept.disableFields"
                                        >
                                            <option :value="null" disabled>Seleccione una opción</option>
                                            <option v-for="h in hotels" :key="h.id" :value="h.id">{{ h.name }}</option>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col>
                                <validation-provider name="Conceptos" rules="required">
                                    <b-form-group slot-scope="{ valid, errors }" label="Conceptos">
                                        <b-form-select
                                            :state="errors[0] ? false : valid ? true : null"
                                            v-model="currentConcept.selectConcepts"
                                            :clearable="false"
                                            :disabled="currentConcept.disableFields"
                                        >
                                            <option :value="null" disabled>Seleccione una opción</option>
                                            <option v-for="item in catalogPerformanceConcepts" :key="item.id" :value="item.id">{{ item.name }}</option>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col>
                                <b-form-group>
                                    <label>Valor</label>
                                    <input
                                        v-model="currentConcept.value"
                                        type="text"
                                        class="form-control"
                                        :disabled="currentConcept.disableFields"
                                        @keypress="onlyNumberWithDecimal"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="" name="status">
                                    <b-form-group>
                                        <label>Status</label>
                                        <b-form-checkbox v-model="currentConcept.status" switch />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <hr>

                        <b-button
                            variant="primary"
                            type="submit"
                            class="float-right "
                            :disabled="invalid || isUpdatingPerformanceConcept"
                        >Guardar
                        </b-button>
                    </b-form>
                </validation-observer>


            </div>
            <div v-else-if="!isLoadingPerformanceConcept && !isUpdatingPerformanceConcept">
                <b-alert variant="danger" show >
                    <div class="alert-body"> No se han encontrado registros válidos </div>
                </b-alert>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import { validYears, isANumber } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils";
import Ripple from 'vue-ripple-directive';

export default {
    mixins: [utils],
    directives: { Ripple, },
    data() {
        return {
            idConcept: this.$route.params.id,
            isUpdatingPerformanceConcept: false,
            isLoadingPerformanceConcept: false,

            currentConcept: null
        };
    },
    async mounted() {
        await this.init()
    },
    computed: {
        ...mapState('fivesClubProfit',['performanceConcept','catalogPerformanceConcepts']),
        ...mapState('start',['hotels']),

        listyears(){
            return validYears()
        },
    },
    methods: {
        ...mapActions('start',['getHotels']),
        ...mapMutations('start',['setHotels']),
        ...mapActions('fivesClubProfit', ['fetchPerformanceConfigutation','fetchCatalogPerformanceConcept','savePerformanceConfigutation','updatePerformanceConfigutation']),
        ...mapMutations('fivesClubProfit',['setPerformanceConcepts', 'setCatalogPerformanceConcepts']),

        async init() {
			if(isANumber(this.idConcept)){
                this.isLoadingPerformanceConcept = true
                const concept = await this.fetchPerformanceConfigutation({performanceconceptsId: this.idConcept})
                if (concept.length > 0) {
                    this.currentConcept = concept[0]

                    this.currentConcept = {
                        performanceId: concept[0].id,
                        year: concept[0].year,
                        selectedHotel: concept[0].HotelId,
                        status: concept[0].status,
                        selectConcepts: concept[0].conceptId,
                        value: parseFloat(concept[0].value).toFixed(4),
                        disableFields: concept[0].status === false
                    }

                    const concepts = await this.fetchCatalogPerformanceConcept()
                    this.setCatalogPerformanceConcepts(concepts)

                    if( this.hotels.length === 0 ){
                        const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }
                        const hotels = await this.getHotels(payloadHotels)
                        this.setHotels(hotels)
                    }
                } else this.currentConcept = null
                this.isLoadingPerformanceConcept = false
            }
        },

        async saveConfiguration() {
            const isConfirmed = await this.confirmSaving()
            if (isConfirmed) {
                this.isUpdatingPerformanceConcept = true
                const payload = {
                    ...this.currentConcept,
                    conceptsId: this.currentConcept.selectConcepts,
                    hotelId: this.currentConcept.selectedHotel,
                }

                delete payload.selectConcepts
                delete payload.selectedHotel

                const response = await this.updatePerformanceConfigutation(payload)

                if (response?.status) {
                    this.isUpdatingPerformanceConcept = false
                    this.goToHome({
                        reload: true,
                        hotelId: this.currentConcept.selectedHotel,
                        year: this.currentConcept.year,
                    })
                }
                this.isUpdatingPerformanceConcept = false
            }
        },
        async confirmSaving() {
            const { isConfirmed } = await this.$swal.fire({
                title: "¿Deseas guardar la información?",
                text: "Favor de confirmar",
                showDenyButton: true,
                confirmButtonText: "Sí, guardar",
                denyButtonText: "Cancelar",
                customClass: {
                    confirmButton: 'swal-button-yes',
                    denyButton: 'swal-button-cancel',
                },
            });
            return isConfirmed
        },
        goToHome(params){
            this.$router.push({ name: 'concept-config', params })
        },
    },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
    .mb-0.table-responsive {
        max-height: 369px;
    }
    .table-responsive th {
        padding: 10px 10px 10px 10px !important;
        font-size: 10px;
    }
    .table-responsive th div {
        font-size: 10px;
    }
    .table-responsive td {
        padding: 5px !important;
        font-size: 11px;
    }
    .buttonInfo button {
        padding: 9px;
    }

    .buttonInfo {
        display: table-cell;
    }
}

.swal-button-yes {
    background-color: #3085d6;
    color: #fff;
    margin-right: 10px;  /* Ajusta la separación a tu preferencia */
}

/* Estilo para el botón de cancelación */
.swal-button-cancel {
    background-color: #d33;
    color: #fff;
}
</style>
